<template>
	<!-- 下载弹窗内容 -->
	<div class="downloadBox">
		<div class="title">{{ $t('home.downloadUrl') }}</div>
		<div class="btnList">
			<div class="btnItem" v-if="activeItem.gmLink" @click="setDownloadLog('gm')">
				<a v-if="isMobile" @click="handleDownloadTips()">
					<img class="tips" :src="downloadTips">
					<img :src="gmImg">
				</a>
				<a v-else :href="activeItem.gmLink" download>
					<img class="tips" :src="downloadTips">
					<img :src="gmImg">
				</a>
			</div>
			<div class="btnItem btnMore" v-if="activeItem.gmLink">
				<div class="textBtn">
					<a v-if="isMobile" @click="handleDownloadTips()">
						{{ $t('home.gameDownload') }}
					</a>
					<a v-else :href="activeItem.gmLink" download>
						{{ $t('home.gameDownload') }}
					</a>
				</div>
				<div class="miniBtn" v-if="activeItem.googleLink || activeItem.onestoreLink || activeItem.sswUrl">
					<div class="iconBtn" v-if="activeItem.googleLink" @click="setDownloadLog('google')">
						<a :href="activeItem.googleLink" download>
							<img src="~@/assets/images/common/google_icon.png">
						</a>
					</div>
					<div class="iconBtn" v-if="activeItem.onestoreLink" @click="setDownloadLog('onestore')">
						<a :href="activeItem.onestoreLink" download><img src="~@/assets/images/common/onstore_icon.png"></a>
					</div>
					<div class="iconBtn" v-if="activeItem.sswUrl" @click="setDownloadLog('samsung')">
						<a :href="activeItem.sswUrl" download><img src="~@/assets/images/common/samsung_icon.png"></a>
					</div>
				</div>
			</div>
			<template v-else>
				<div class="btnItem" v-if="activeItem.googleLink" @click="setDownloadLog('google')">
					<a :href="activeItem.googleLink" download>
						<img :src="googleImg">
					</a>
				</div>
				<div class="btnItem" v-if="activeItem.onestoreLink" @click="setDownloadLog('onestore')">
					<a :href="activeItem.onestoreLink" download><img :src="onestoreImg"></a>
				</div>
			</template>
		</div>
		
		<van-popup v-model:show="showDownloadTips" round position="bottom" teleport="body" @click-overlay="showDownloadTips = false" >
			<div class="riskTips">
				<img class="imgs" :src="downloadRiskTips">
				<p>안녕하세요 플레이어님</p>
				<p>다운로드 과정에서 "유해한 파일일 수도 있음"이라는 경고 안내 문구가 표시되는 데요. </p>
				<p>이는 저희 어플이 모바일 스토어/구글 스토어에 출시되지 않아 발생하는 경고문입니다. "무시하고 다운로드"를 클릭하시면 됩니다. </p>
				<p>저희 어플은 여러분에게 아무런 해를 끼치지 않을 것입니다.</p>
				<a class="btnBox" :href="activeItem.gmLink" download>
					{{ $t('home.gameDownload') }}
				</a>
			</div>
		</van-popup>
	</div>
</template>

<script setup>
	import { getCurrentInstance,onMounted,ref,computed,defineProps } from 'vue'
	import useConfigStore from '@/store/modules/config'
	const { proxy } = getCurrentInstance();
	// Import the functions you need from the SDKs you need
	import { initializeApp } from "firebase/app";
	import { getAnalytics, logEvent } from "firebase/analytics";
	const app = initializeApp(proxy.partten.firebaseConfig);
	const analytics = getAnalytics(app);
	
	const lang = proxy.$i18n.locale
	const gmImg = require('@/assets/images/'+lang+'/gm_download.png')
	const googleImg = require('@/assets/images/'+lang+'/google_download.png')
	const onestoreImg = require('@/assets/images/'+lang+'/onestore_download.png')
	const downloadTips = require('@/assets/images/'+lang+'/coupon_tips.png')
	const downloadRiskTips = require('@/assets/images/'+lang+'/download_tips.png')
	const props = defineProps({
		gameInfo: Object
	})
	const activeItem = ref({})
	const showDownloadTips = ref(false)
	const isMobile = computed(() => {
		return useConfigStore().isMobile
	})
	onMounted(() => {
		activeItem.value = Object.assign({},props.gameInfo)
	})
	const setDownloadLog = (type) => {
		logEvent(analytics, 'download_'+type+'_'+activeItem.value.gameId, { des: activeItem.value.gameName })
	}
	const handleDownloadTips = () => {
		showDownloadTips.value = true
	}
</script>

<style lang="less" scoped>
	.riskTips{
		max-width: 750px;margin: 0 auto;
		padding: 1.75rem 1.3125rem 1.5rem;box-sizing: border-box;
		.imgs{
			width: 95%;margin-left: 5%;margin-bottom: 0.75rem;
		}
		p{
			padding: 0.3125rem 0;width: 100%;text-align: center;font-weight: 500;
		}
		.btnBox{
			display: inline-block;width: calc(80% - 1.3125rem);height: 1.875rem;line-height: 1.875rem;
			font-size: 0.9375rem;cursor: pointer;margin: 1.25rem 10% 0;
			background: linear-gradient(to right,#51BAC0,#85D6DA);color: #fff;
			padding: 0.25rem 0.625rem;border-radius: 0.625rem;text-align: center;
		}
	}
</style>